<template>
	<div class="bg-area center">
		<div class="login_area">
			<!-- 登录logo -->
			<div class="paddt30 center">
				<img class="login_logo display-block" src="../assets/img/login_logo.png" alt="">
			</div>
			<!-- 登陆方式选中 -->
			<div class="align-items-center paddlr90 mart57 marb30" v-if="status!=4&&status!=3&&status!=6">
				<div class="relative paddb12 login-way" @click="chooseWay(1)">
					<span class="fz16 co-333333" :class="status == 1 ? 'font-blod ' : ''">手机号登录</span>
					<div class="absolute  absolute left0 bottom0 right0"
						:class="status == 1 ? 'bg-010085 line ' : 'bg-white'"></div>
				</div>
				<div class="relative paddb12 login-way marl40" @click="chooseWay(2)">
					<span class="fz16 co-333333" :class="status == 2 ? 'font-blod' : ''">账号密码登录</span>
					<div class="absolute  absolute left0 bottom0 right0"
						:class="status == 2 ? 'bg-010085 line1' : 'bg-white'"></div>
				</div>
				<div class="relative paddb12 login-way marl40" @click="chooseWay(5)">
					<span class="fz16 co-333333" :class="status == 3 ? 'font-blod' : ''">扫码登入</span>
					<div class="absolute  absolute left0 bottom0 right0"
						:class="status == 5 ? 'bg-010085 line' : 'bg-white'"></div>
				</div>
			</div>
			<div v-if="status == 1">
				<!-- //手机号登入 -->
				<div class="center flex-column">
					<div class="phone-input">
						<input type="text" v-model="iphonelogin.phone" class="input fz14 co-333333" maxlength="11"
							placeholder="请输入手机号">
					</div>
					<!-- 验证码登录 -->
					<div class="phone-input display-flex mart20" v-if="loginway == 1">
						<input type="text" class="input fz14 co-333333 flex-1 marr24" v-model="iphonelogin.code"
							maxlength="6" placeholder="请输入验证码">
						<getCode ref="getCode" :phone='iphonelogin.phone' :type='1' codeStatus="获取验证码">
						</getCode>
					</div>
				</div>
				<!-- 登录按钮 -->
				<div class="login-btn bg-DCFF03 center cure-pointer" @click="registeredsubmit">
					<span class="fz16 co-010085">登录</span>
				</div>
			</div>
			<div v-if="status == 2">
				<!-- //账号密码登入 -->
				<div class="center flex-column">
					<div class="phone-input">
						<input type="text" v-model="phone" class="input fz14 co-333333" maxlength="11"
							placeholder="请输入手机号">
					</div>
					<!-- 账号密码登录 -->
					<div class="phone-input display-flex mart20">
						<input type="password" class="input fz14 co-333333 flex-1 marr24" v-model="password"
							maxlength="12" placeholder="请输入密码">
						<div class="forget h100 center cure-pointer" @click="chooseWay(3)">
							<span class="fz14 co-010085">忘记密码？</span>
						</div>
					</div>
				</div>
				<!-- 登录按钮 -->
				<div class="login-btn bg-DCFF03 center cure-pointer" @click="registeredsubmit">
					<span class="fz16 co-010085">登录</span>
				</div>
			</div>
			<div v-if="status == 5">
				<!-- //微信扫码登入 -->
				<div class=" flex-column align-items-center ">
					<div class="text-center  marb10">
						<div id="wechat_code"
							style="display: flex; align-items: center; justify-content: center;margin: 0px 0px;flex-direction:column;">
							<wxlogin :appid="WxLogInData.appid" scope="snsapi_login" theme="'black'"
								:redirect_uri="encodeURIComponent(WxLogInData.redirect_uri)" :href="WxLogInData.href"
								rel="external nofollow"></wxlogin>

						</div>
					</div>
				</div>
			</div>
			<div v-if="status == 3">
				<!-- //忘记密码码-->
				<div class="column paddlr90 mart57 marb30">
					<div class="fz16 co-333333 font-blod mart57 center">忘记密码</div>
					<div class="center mart42">
						<div class="phone-input">
							<input type="text" v-model="finduserpass.newiphone" class="input fz14 co-333333"
								maxlength="11" placeholder="请输入手机号">
						</div>
					</div>
					<div class="center mart20">
						<div class="phone-input display-flex">
							<input class="input fz14 co-333333 flex-1 marr24" type="text" v-model="finduserpass.code"
								maxlength="6" placeholder="请输入验证码">
							<getCode ref="getCode" :phone='finduserpass.newiphone' :type='2' codeStatus="获取验证码">
							</getCode>
						</div>
					</div>
					<div class="center mart20">
						<div class="phone-input">
							<input type="password" v-model="finduserpass.newpassword" class="input fz14 co-333333"
								maxlength="11" placeholder="请输入密码">
						</div>
					</div>					
					<div class="center mart20">
						<div class="phone-input">
							<input type="password" v-model="finduserpass.againpassword" class="input fz14 co-333333"
								maxlength="11" placeholder="请再次输入密码确认">
						</div>
					</div>
					<div class="center">
						<div class="login-btn bg-DCFF03 center cure-pointer" @click="registeredsubmit">
							<span class="fz16 co-010085">提交</span>
						</div>
					</div>
					<div class="center mart20">
						<div class="mart20 center">
							<span class="fz14 co-333333">想起来了？</span>
							<span class="fz14 co-010085 register" @click="chooseWay(1)">登录</span>
						</div>
					</div>
				</div>
			</div>
			<div v-if="status == 4">
				<!-- //注册 -->
				<div class="column paddlr90 mart57 marb30">
					<div class="fz16 co-333333 font-blod mart57 center">注册</div>
					<div class="center mart42">
						<div class="phone-input">
							<input type="text" v-model="registeredform.phoneNum" class="input fz14 co-333333"
								maxlength="11" placeholder="请输入手机号">
						</div>
					</div>

					<div class="center mart20">
						<div class="phone-input">
							<input type="password" v-model="registeredform.password" class="input fz14 co-333333"
								maxlength="11" placeholder="请输入密码">
						</div>
					</div>
					<div class="center mart20">
						<div class="phone-input display-flex">
							<input class="input fz14 co-333333 flex-1 marr24" type="text" v-model="registeredform.code"
								maxlength="6" placeholder="请输入验证码">
							<getCode ref="getCode" :phone='registeredform.phoneNum' :type='3' codeStatus="获取验证码">
							</getCode>
						</div>
					</div>
					<div class="center">
						<div class="login-btn bg-DCFF03 center cure-pointer" @click="registeredsubmit">
							<span class="fz16 co-010085">提交注册</span>
						</div>
					</div>
					<div class="center mart20">
						<div class="mart20 center">
							<span class="fz14 co-333333">已有账号？</span>
							<span class="fz14 co-010085 register" @click="chooseWay(1)">登录</span>
						</div>
					</div>
					<div class="center mart82 cure-pointer">
						<div class="center paddb26">
							<div class="center cure-pointer" @click="isAgree = !isAgree">
								<img class="no_choose display-block"
									:src="require(`@/assets/img/${isAgree ? 'is_choose' : 'no_choose'}.png`)" alt="" />
								<span class="fz14 co-333333 display-block marl11">阅读并同意</span>
							</div>
							<span class="fz14 co-010085" @click="dialogVisible=true">《注册协议》</span>
						</div>
					</div>
				</div>
			</div>
			
			<!-- 提示 -->
			<div class="mart20 center" v-if="status!=4&&status!=3&&status!=6">
				<span class="fz14 co-333333">还没有账号？</span>
				<span class="fz14 co-010085 register" @click="chooseWay(4)">注册</span>
			</div>
			<!-- 微信登录 -->
			<div class="center flex-column paddb26" v-if="status!=4&&status!=3&&status!=6">
				<img class="wechat_login marb10 mart108" src="../assets/img/wechat_login.png" alt=""
					@click="chooseWay(5)">
				<span class="fz14 co-3B3B3B">微信登录</span>
			</div>
			
		</div>
		<el-dialog title="" :lock-scroll='false' v-model="dialogVisible" :center='true' :width='650' >
			<h3 style="text-align: center;" class="marb30">用户协议</h3>
			<div class='paddlr10' style="overflow:auto;height: 600px;">
			<div v-html="content" class="html-box ql-editor content-detail">
			</div>	
			</div>
			
		</el-dialog>
		<div v-loading.fullscreen.lock="fullscreenLoading"></div>
	</div>
</template>

<script>
	import wxlogin from 'vue-wxlogin';
	import getCode from '@/components/getCode.vue';
	import {
		getCurrentInstance,
		ref,
		reactive,
		watch
	} from 'vue';
	import axios from 'axios';
	export default {
		name: "Home",
		components: {
			getCode,
			wxlogin
		},
		setup() {
			const req1 = getCurrentInstance();
			const request = req1.appContext.config.globalProperties;
			// let WxLogInData = reactive({
			// 	data: {
			// 		appid: "",
			// 		redirect_uri: encodeURIComponent(),
			// 	}
			// })
			return {
				request,
			

			};
		},
		data() {
			return {
				fullscreenLoading: true,
				finduserpass: { //找回密码
					code: '', //验证码
					newiphone: '', //手机号
					newpassword: '', //新密码
					againpassword: '' //请再次输入新密码
				},
				iphonelogin: {
					phone: '',
					code: ''
				},
				status: 1, // 1登录 2账号密码登入 3忘记密码 4注册 5微信扫码登入 6绑定手机号
				loginway: 1, // 1验证码登录 2账号密码登录 3扫码登入
				phone: '', // 手机号
				code: '', // 验证码
				password: '', // 密码
				passwordtwo: '', // 确认密码
				isAgree: false, // 是否同意协议
				registeredform: {
					phoneNum: '',
					password: '',
					code: '',
				},
				wxform: {
					phoneNum: "",
					code: '',
				},
				User_Agreement:'',
				dialogVisible:false,
				content:'',
				WxLogInData:{}
			}
		},
		watch: {
			
		},
		mounted() {
		
			this.$req({
				method: 'get',
				url: '/api/wechat/getUrl',
				success: res => {
					
					this.WxLogInData = res;
					this.fullscreenLoading = false;
					
				},
				fail: error => {
					this.fullscreenLoading = false;
				}
			});
			this.$req({
				method: 'post',
				url: '/api/wechat/webWechatUrl',
				success: res => {			
					this.WxLogInData.href = res
					this.fullscreenLoading = false;
				},
				fail: error => {
					this.fullscreenLoading = false;
				}
			});
			this.$req({
				method: 'get',
				url: '/api/config',
				data:{
					name:'user_rule'
				},
				success: res => {			
					this.content =res.user_rule;
				},
				fail: error => {}
			});
		
		},
		created() {

		},
		methods: {
			authorize(token) {
				let that = this
				axios({
					url: this.request.$httpUrl + '/api/member',
					method: 'get',
					headers: {
						'Content-Type': 'application/json',
						"Authorization": token,
					},
				}).then(function(res) {
					console.log(res)
					that.$store.commit('setUserInfo', res.data.data);
					that.$message({
						type: 'success',
						message: '授权登入成功'
					});
					this.$router.push({
						name: 'Indexpart',
					});
				}).catch(function(error) {
					console.log(error)
				});
			},
			// 登录方式选中
			chooseWay(e) {
				this.status = e

			},
			checkiphone(iphone, password, code) {
				let phone = /^1[3456789]\d{9}$/;
				if (!phone.test(iphone, "phone")) {
					this.$message.error("请输入正确的手机号")
					return false;
				}
				if (!password) {
					this.$message.error('请输入密码');
					return false
				}
				if (!code) {
					this.$message.error('请输入验证码');
					return false
				}


			},
			registeredsubmit() {
				// this.fullscreenLoading = false
				if (this.status == 6) {
					console.log('绑定手机号')
				}
				if (this.status == 4) {
					this.checkiphone(this.registeredform.phoneNum, this.registeredform.password, this
						.registeredform.code)
					if (this.isAgree === false) {
						this.$message.success('未勾选注册协议');
						return
					}
					this.fullscreenLoading = true;
					this.$req({
						method: 'post',
						url: '/api/login/register',

						data: {
							phone: this.registeredform.phoneNum,
							password: this.registeredform.password,
							code: this.registeredform.code,
						},
						success: res => {
							this.$message.success('注册成功,去登入');
							this.status = 1
							this.fullscreenLoading = false;
						},
						fail: error => {
							this.fullscreenLoading = false;
							this.$message.error(error.data.message);
						}
					});

				}
				if (this.status == 1) {
					let phone = /^1[3456789]\d{9}$/;
					if (!phone.test(this.iphonelogin.phone, "phone")) {
						this.$message.error("请输入正确的手机号")
						return false;
					}
					this.fullscreenLoading = true;
					this.$req({
						method: 'post',
						url: '/api/login/login',
						data: {
							phone: this.iphonelogin.phone,
							code: this.iphonelogin.code,
							type: 2
						},
						success: res => {
							this.fullscreenLoading = false;
							localStorage.setItem('token', res.token);
							localStorage.setItem('timeout', res.expire);
							this.$message({
								type: 'success',
								message: '登录成功'
							});
							window.requestAnimationFrame(() => {
								this.request.tokenSuccess();
							})
							this.$router.push({
								name: 'Indexpart',
							});
						},
						fail: error => {
							
							this.fullscreenLoading = false;
							this.$message.error(error.data.message);
						}
					});
				}

				if (this.status == 2) {
					let phone = /^1[3456789]\d{9}$/;
					if (!phone.test(this.phone, "phone")) {
						this.$message.error("请输入正确的手机号")
						return false;
					}
					if (!this.password) {
						this.$message.error('请输入密码');
						return false
					}
					this.fullscreenLoading = true;
					this.$req({
						method: 'post',
						url: '/api/login/login',
						data: {
							phone: this.phone,
							password: this.password,
							type: 1
						},
						success: res => {
							this.fullscreenLoading = false;
							localStorage.setItem('token', res.token);
							localStorage.setItem('timeout', res.expire);
							this.$message({
								type: 'success',
								message: '登录成功'
							});
							window.requestAnimationFrame(() => {
								this.request.tokenSuccess();
							})
							this.$router.push({
								name: 'Indexpart',
							});
						},
						fail: error => {
							this.fullscreenLoading = false;
							this.$message.error(error.data.message);
						}
					});
				}

				if (this.status == 3) {
					// code: '', //验证码
					// newiphone: '', //手机号
					// newpassword: '', //新密码
					// againpassword: '' //请再次输入新密码
					this.checkiphone(this.finduserpass.newiphone, this.finduserpass.newpassword, this.finduserpass
						.code)
					if (this.finduserpass.newpassword != this.finduserpass.againpassword) {
						this.$message({
							type: 'error',
							message: '两次密码不一致'
						});
						return
					}
					this.$req({
						method: 'post',
						url: '/api/login/forget',
						data: {
							phone: this.finduserpass.newiphone,
							password: this.finduserpass.newpassword,
							code: this.finduserpass.code,
							repassword: this.finduserpass.againpassword
						},
						success: res => {
							this.$message.success('修改成功,去登入');
							this.status = 1
						},
						fail: error => {

							this.$message.error(error.data.message);
						}
					});
				}
			},
			godetails(url, id) {
				this.$router.push({
					name: url,
					query: {
						id: id
					}
				});
			},

		}
	};
</script>

<style lang="less" >
	html {
		overflow: hidden;
	}

	.no_choose {
		width: 21px;
		height: 21px;
	}

	.bg-area {
		min-width: 1200px;
		min-height: 100vh;
		background: url('../assets/img/login_bg.png') no-repeat center;
		background-size: cover;
	}

	.login_logos {
		width: 150px;
		width: 150px;
	}

	.login_area {
		// height: 500px;
		// width: 450px;
		background: #FFFFFF;

		.login_logo {
			width: 58px;
			height: 58px;
		}

		.line {
			height: 2px;
			top: 30px;
			left: 5px;
			width: 70px;
			background-color: #010085;

		}

		.line1 {
			height: 2px;
			top: 30px;
			left: 5px;
			width: 80px;
			background-color: #010085;

		}

		.login-way:hover {
			cursor: pointer;
		}

		.register:hover {
			cursor: pointer;
		}

		.wechat_login {
			width: 34px;
			height: 34px;
		}

		.wechat_login:hover {
			cursor: pointer;
		}
	}

	.login-btn {
		width: 300px;
		height: 50px;
		border-radius: 6px;
		margin: 0 auto;
		margin-top: 60px;
	}

	.phone-input {
		width: 268px;
		height: 50px;
		padding: 0px 16px;
		border-radius: 6px;
		border: 1px solid #999999;

		input:-internal-autofill-selected {
			appearance: menulist-button;
			background-color: transparent !important;
			background-image: none !important;
			color: -internal-light-dark(black, white) !important;
		}
	}

	.input {
		height: 100%;
		padding: 0px;
		margin: 0px;
		outline: none;
		border: 0;
	}

	.input::-webkit-input-placeholder {
		color: #999999;
	}

	input::-webkit-inner-spin-button {
		-webkit-appearance: none !important;
		margin: 0;
		-moz-appearance: textfield;

	}
</style>
